import { Typography, Box, Divider } from '@mui/material';
import PropTypes from 'prop-types'

export const PrincipalBanner = ({ title, imgPath }) => {

  return (
    <Box component='section' sx={{
      height: { xs: 'auto', lg: '75vh' },
      backgroundImage: `url(${imgPath})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: { xs: '25% center', lg: 'center' },
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    }}>
      <Box sx={{
        padding: '50px',
        marginLeft: { xs: '-42px', lg: '0' }
      }}>
        <Box sx={{
          maxWidth: '800px',
          width: { xs: '178px', lg: '455px' },
        }}>
          <Divider orientation="vertical" variant="middle" flexItem
            sx={{
              borderWidth: '1px',
              borderColor: '#dee2e6',
            }}
          />
          <Typography variant='h1' sx={{
            color: '#fff',
            fontSize: { xs: '10px',sm: '15px', md: '25px', lg: '40px' },
            fontWeight: 600,
          }}>
            
            {title}
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem
            sx={{
              borderWidth: '1px',
              borderColor: '#dee2e6',
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

PrincipalBanner.propTypes = {
  title: PropTypes.string.isRequired,
  imgPath: PropTypes.string.isRequired
}