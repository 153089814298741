import { languagesTypes } from '../types/types';

export const pages =  {
    [languagesTypes.english]: [
      { title: 'ABOUT US', url: '/about-us' },
      { title: 'OUR HISTORY', url: '/our-history' },
      { title: 'SOLUTIONS', url: '/solutions' },
      { title: 'SERVICES', url: '/services' },
      { title: 'CONTACT US', url: '/contact-us' }
    ],
    [languagesTypes.spanish]: [
      { title: 'SOBRE NOSOTROS', url: '/sobre-nosotros' },
      { title: 'NUESTRA HISTORIA', url: '/nuestra-historia' },
      { title: 'SOLUCIONES', url: '/soluciones' },
      { title: 'SERVICIOS', url: '/servicios' },
      { title: 'CONTÁCTENOS', url: '/contactenos' }
    ],
    [languagesTypes.portugues]: [
      { title: 'SOBRE NÓS', url: '/sobre-nos' },
      { title: 'NOSSA HISTÓRIA', url: '/nossa-historia' },
      { title: 'SOLUÇÕES', url: '/solucoes' },
      { title: 'SERVIÇOS', url: '/servicos' },
      { title: 'CONTATE-NOS', url: '/contate-nos' }
    ]
  }

  export const policyLinks =  {
    [languagesTypes.english]: [
      { text: 'ABOUT US', url: '/about-us' },

    ],
    [languagesTypes.spanish]: [
      { text: 'SOBRE NOSOTROS', url: '/sobre-nosotros' },

    ],
    [languagesTypes.portugues]: [
      { text: 'SOBRE NÓS', url: '/sobre-nos' },
    ]
  }