import { useState } from 'react'
import { languagesTypes } from "../types/types"

const lang = localStorage.getItem('lang') || languagesTypes.english

export const useLastVisit = () => {
    const [currentLang, setCurrentLang] = useState(lang)

    const changeLang = (lang) => {
        localStorage.setItem('lang', lang)
        setCurrentLang(lang)
    }

    return {
        currentLang,
        changeLang,
    }

}
