import { Box, Typography } from '@mui/material';
import { useContext } from 'react';

import video from '../../assets/videos/telemedicinaWta.mp4'
import { languagesTypes } from '../../types/types';
import { UiContext } from '../../context';


const bannerTitles = {
  [languagesTypes.english]: {
    title: 'International Medical',
    title2: 'Coverage of Excellence',
    text1: 'Upon receiving a request for assistance, our professional medical team at WTA by HAS assesses the risk level and urgency to recommend the most appropriate treatment. We optimize available resources and guarantee patient satisfaction.',
    text2: 'With a solid experience in Telemedicine services worldwide, we are here to take care of your customers, wherever they are.'
  },
  [languagesTypes.spanish]: {
    title: 'Cobertura Médica',
    title2: ' Internacional de Excelencia',
    text1: 'Al recibir una solicitud de asistencia, nuestro equipo médico profesional de WTA by HAS evalúa el nivel de riesgo y la urgencia para recomendar el tratamiento más adecuado. Optimizamos los recursos disponibles y garantizamos la satisfacción del paciente.',
    text2: 'Con una sólida experiencia en servicios de telemedicina en todo el mundo, estamos aquí para atender a sus clientes, estén donde estén.'
  },
  [languagesTypes.portugues]: {
    title: 'Cobertura Médica',
    title2: 'Internacional de Excelência',
    text1: 'Ao receber uma solicitação de assistência, nossa equipe médica profissional da WTA by HAS avalia o nível de risco e a urgência para recomendar o tratamento mais adequado. Otimizamos os recursos disponíveis e garantimos a satisfação do paciente.',
    text2: 'Com uma sólida experiência em serviços de telemedicina em todo o mundo, estamos aqui para cuidar de seus clientes, onde quer que eles estejam.'
  }
}

export const OurCoverage = () => {

  const { currentLang } = useContext(UiContext)

  return (
    <Box component='section'
      sx={{
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        paddingBottom: { xs: '0.5rem', md: '3rem' },
        px: { md: '5rem' },
      }}
    >
      <Box
        sx={{
          width: { md: '60%' }
        }}
      >
        <Box
          sx={{
            p: { xs: '1rem' }
          }}
        >
          <video src={video} controls className='video'>
          </video>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { md: '40%' },
          px: '2rem',
          gap: '1.5rem',
          paddingTop: { xs: '1rem', md: '4rem' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant='h3'
            sx={{
              fontSize: { xs: '30px', md: '39px' },
              color: '#53e0b4',
              fontWeight: 'bold',
              textAlign: 'center',
              padding: '0 1rem'
            }}
          >
            {bannerTitles[currentLang].title}
          </Typography>
          <Typography variant='h3'
            sx={{
              fontSize: { xs: '30px', md: '39px' },
              color: '#53e0b4',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            {bannerTitles[currentLang].title2}
          </Typography>
        </Box>
        <Typography variant='body1'
          sx={{
            textAlign: { xs: 'justify', md: 'start' }
          }}
        >
          {bannerTitles[currentLang].text1}
        </Typography>
        <Typography
          sx={{
            textAlign: { xs: 'justify', md: 'start' }
          }}
        >
          {bannerTitles[currentLang].text2}
        </Typography>
      </Box>
    </Box>
  )
}
