import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Divider, Avatar, IconButton, Box, MenuItem, AppBar, Toolbar, Typography, Container, Tooltip, Fab } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


import { UiContext } from '../context';
import { getImageURL, getLangByUrl, getPathByLang, pages } from '../helpers';
import { NavBarMobile } from './NavBarMobile';
import { NavBarDesktop } from './NavBarDesktop';
import { ScrollTop } from './partials/ScrollTop';
import { useNavegate } from '../hooks/useNavegate';

export const NavBar = () => {

  const [anchorLanguageDropDown, setAnchorLanguageDropDown] = useState(null);
  const handleOpenLanguageMenu = (event) => {
    setAnchorLanguageDropDown(event.currentTarget);
  };

  const { navigate } = useNavegate();


  const [mobileNavMenu, setMobileNavMenu] = useState(false);

  const handleOpenNavBarMobileMenu = () => {
    setMobileNavMenu(!mobileNavMenu)
  }

  const { currentLang, changeLang } = useContext(UiContext)
  const { pathname } = useLocation()

  useEffect(() => {
    // Actualiza el idioma al cambiar la url, o sea, cuando se navega
    const lang = getLangByUrl(pathname)
    changeLang(lang)
  }, [changeLang, pathname])

  const handleLanguage = (lang) => {
    // Navega a la página correspondiente
    changeLang(lang)

    const urlToNavigate = getPathByLang(pathname, lang)

    navigate(urlToNavigate)
  };

  return (
    <>
      <AppBar
        sx={{
          position: 'sticky',
          top: 0,
          left: 0,
          backgroundColor: '#fff',
          padding: '1rem'
        }}>
        <Container maxWidth="xl" sx={{
          padding: '0'
        }}>
          <Toolbar disableGutters>
            <Box component='figure' className='img-logo' onClick={() => navigate('/about-us')}>
              <Box component='img' src={getImageURL('logo-nav.png')} alt="Logo de WTA" sx={{
                cursor: 'pointer'
              }} />
            </Box>
            {/* navbar mobile */}
            <NavBarMobile
              handleOpenMenu={handleOpenNavBarMobileMenu}
            />

            {/* navbar desktop */}
            <NavBarDesktop
              pages={pages[currentLang]}
              handleLanguage={handleLanguage}
              anchorLanguageDropDown={anchorLanguageDropDown}
              handleOpenLanguageMenu={handleOpenLanguageMenu}
              setAnchorLanguageDropDown={setAnchorLanguageDropDown}
            />
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        component='ul'
        sx={{
          display: { xs: 'block', md: 'none' },
          position: 'fixed',
          zIndex: 1000,
          backgroundColor: 'black',
          width: '100%',
          clipPath: mobileNavMenu ? 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)' : 'polygon(0 0, 100% 0, 100% 0, 0% 0)',
          transition: 'clip-path 0.5s ease-in-out',
        }}
        role='menu'
      >
        {pages[currentLang].map((page) => (
          <React.Fragment key={page.title + 'navbarmobile'} >
            <MenuItem
              role='menuitem'
              onClick={() => {
                handleOpenNavBarMobileMenu()
                navigate(page.url)
              }}
              sx={{
                listStyle: 'none'
              }}>
              <Typography sx={{
                textAlign: 'center',
                color: '#fff'
              }}>{page.title}</Typography>
            </MenuItem>

            <Divider component="li"
              className='navbar-mobile-divider'
              sx={{
                borderColor: 'gray',
                margin: '0 !important',
                height: 0,
              }} />

          </React.Fragment>
        ))}
        <Tooltip title="Select Language">
          <IconButton onClick={handleOpenLanguageMenu} aria-label='select language' sx={{ padding: '6px 16px' }}>
            <Avatar alt="Globo terráqueo" src={getImageURL('traductor.svg')} />
            <KeyboardArrowDownIcon sx={{
              color: 'black',
              alignSelf: 'center'
            }} />
          </IconButton>
        </Tooltip>
      </Box>
      <ScrollTop>
        <Fab size="small" aria-label="scroll back to top" className='scroll-top-button'>
          <KeyboardArrowUpIcon sx={{ color: 'white' }} />
        </Fab>
      </ScrollTop>
    </>

  );
}
