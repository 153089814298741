import { lazily } from 'react-lazily';
import { languagesTypes } from '../../types/types';


const { SobreNosPage } = lazily(() => import('../pages/SobreNosPage'));
const { NossaHistoriaPage } = lazily(() => import('../pages/NossaHistoriaPage'));
const { SolucoesPage } = lazily(() => import('../pages/SolucoesPage'));
const { ServicosPage } = lazily(() => import('../pages/ServicosPage'));
const { ContateNosPage } = lazily(() => import('../pages/ContateNosPage'));

export const portugueseRoutes = [
    {
        path: 'sobre-nos',
        element: <SobreNosPage />,
        [languagesTypes.english]: '/about-us',
        [languagesTypes.spanish]: '/sobre-nosotros'
    },
    {
        path: 'nossa-historia',
        element: <NossaHistoriaPage />,
        [languagesTypes.english]: '/our-history',
        [languagesTypes.spanish]: '/nuestra-historia'
    },
    {
        path: 'solucoes',
        element: <SolucoesPage />,
        [languagesTypes.english]: '/solutions',
        [languagesTypes.spanish]: '/soluciones'
    },
    {
        path: 'servicos',
        element: <ServicosPage />,
        [languagesTypes.english]: '/services',
        [languagesTypes.spanish]: '/servicios'
    },
    {
        path: 'contate-nos',
        element: <ContateNosPage />,
        [languagesTypes.english]: '/contact-us',
        [languagesTypes.spanish]: '/contactenos'
    },
]