import { Typography, Box, Container } from '@mui/material';

import { useContext } from 'react';
import { UiContext } from '../../context';
import { languagesTypes } from '../../types/types';
import { getImageURL } from '../../helpers';

const certifications = {
    [languagesTypes.english]: {
        title1: 'ACCREDITATIONS AND CERTIFICATIONS',
    },
    [languagesTypes.spanish]: {
        title1: 'ACREDITACIONES Y CERTIFICACIONES',
    },
    [languagesTypes.portugues]: {
        title1: 'ACREDITAÇÕES E CERTIFICAÇÕES',
    }
}

const images = [
    getImageURL('iso9001.svg'),
    getImageURL('iso27001.svg'),
    getImageURL('HIPAA.svg'),
    getImageURL('AICPA.svg'),
    getImageURL('liquidweb.svg'),
    getImageURL('LLOYDSDCA.svg'),
]

export const Certifications = () => {

    const { currentLang } = useContext(UiContext);

    return (
        <Box component='section'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#FFFFFF',
                py: '3rem',
                alignItems: 'center',
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '3rem'
                }}
            >
                <Typography variant='h5'
                    sx={{
                        color: '#fff',
                        background: 'linear-gradient(90deg, #376ab0 10%, #428faa 35%, #48a1a3 55%, #53c095 80%)',
                        width: { md: '43rem' },
                        textAlign: 'center',
                        py: '0.20rem',
                        borderRadius: '70px',
                        fontSize: { xs: '0.8rem', md: '1.5rem' },
                        px: { xs: '2rem' }
                    }}
                >
                    {certifications[currentLang].title1}
                </Typography>
                <Container  maxWidth="xl">
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(6, 1fr)' },
                            padding: { xs: '0', md: '0 10rem' },
                            gap: { xs: '40px', md: '80px' },
                            justifyItems: 'center'
                        }}
                    >
                        {images.map((image, index) => (
                            <Box
                                component='figure'
                                key={`image-${index}`}
                                sx={{
                                    height: { md: '100px' },
                                    width: { xs: '100px', md: index == images.length - 1 ? '200%' : '100%' }
                                }}>
                                <Box 
                                component='img'
                                loading='lazy'
                                src={image} 
                                alt={`Certification ${index}`} 
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                }} />
                            </Box>
                        ))}
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}
