import React, { useContext } from 'react';
import { Typography, styled, Box, Stack, Paper, Divider, Button, Link } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import FaxIcon from '@mui/icons-material/Fax';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import { pages } from '../helpers/constants';
import { getImageURL } from '../helpers';
import { UiContext } from '../context';
import { languagesTypes } from '../types/types';
import { useNavegate } from '../hooks/useNavegate';

const textFooter = {
  [languagesTypes.english]: {
    paragraph: 'We specialize in travel assistance and insurance, TPA services and risk management.',
    location: 'Sawgrass 1571 Sawgrass Corporate Parkway, Suite 100, Sunrise, FL 33323, United States of America',
    paragraphTwo: 'All rights reserved. Powered by ilstechnik.com @2024 Has Companies. All users of our online services agree to be bound by the Terms of Service and Privacy Policy available for viewing on our website.',
    documentLinks: [
      { text: 'Privacy Policy', url: 'doc/eng/PRIVACY_POLICY.pdf' },
      { text: 'Web Terms of Use', url: 'doc/eng/TERMS_OF_USE.pdf' },
      { text: 'Cookie Policy', url: '#', id: 'obj' },
    ]
  },
  [languagesTypes.spanish]: {
    paragraph: 'Somos especialistas en asistencia y seguros en viajes, administración de servicios y riesgos TPA.',
    location: '1571 Sawgrass Corporate Parkway, Suite 100, Sunrise, FL 33323, Estados Unidos de América',
    paragraphTwo: 'Todos los derechos reservados. Desarrollado por ilstechnik.com @2024 Has Companies. Todos los usuarios de nuestros servicios en línea aceptan regirse por los términos de servicios y privacidad disponibles para su consulta en nuestra página web.',
    documentLinks: [
      { text: 'Políticas de Privacidad', url: 'doc/esp/POLITICA_DE_PRIVACIDAD.pdf' },
      { text: 'Condiciones de Uso Web', url: 'doc/esp/CONDICIONES_DE_USO_WEB.pdf' },
      { text: 'Política de cookies', url: '#',  id: 'obj' },
    ]
  },
  [languagesTypes.portugues]: {
    paragraph: 'Somos especialistas em assistência e seguros em viajes, administração de serviços e riscos TPA.',
    location: '1571 Sawgrass Corporate Parkway, Suite 100, Sunrise, FL 33323, Estados Unidos da América',
    paragraphTwo: 'Todos os direitos reservados. Desenvolvido por ilstechnik.com @2024 Has Companies. Todos os usuários de nossos serviços on-line aceitam estar de acordo com os termos de serviços e privacidade disponíveis para sua consulta em nosso site.',
    documentLinks: [
      { text: 'Políticas de Privacidade', url: 'doc/por/POLITICA_DE_PRIVACIDADE.pdf' },
      { text: 'Condições de Uso Web', url: 'doc/por/CONDICONES_DE_USO_WEB.pdf' },
      { text: 'Políticas de cookies', id: 'obj' },
    ]
  }

}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: '#fff',
  flexGrow: 1,
  backgroundColor: 'transparent',
  padding: '0',
}));

const contactLinks = [
  {
    icon: <PhoneIcon />,
    text: '+1-305-328-3897',
    link: 'tel:+1-305-328-3897'
  },
  {
    icon: <FaxIcon />,
    text: 'Fax: +1-305-328-3897',
    link: 'tel:+1-305-328-3897'
  },
  {
    icon: <EmailOutlinedIcon />,
    text: 'info@wtabyhas.com',
    link: 'mailto:info@hascompanies.com'
  },

]

const pageLinksStyles = {
  position: 'relative',
  my: 0,
  fontSize: '16px',
  fontWeight: 400,
  color: '#fff',
  display: 'block',
  overflow: 'hidden',
  transition: 'transform 0.5s ease',
  '&:after': {
    content: '""',
    display: 'block',
    width: '100%',
    border: '1px solid #1de9b6',
    position: 'absolute',
    transform: 'translateX(105%)',
    transition: 'width 0.5s ease, color 0.5s ease, transform 0.5s ease',
    right: 0
  },
  '&:before': {
    content: `""`,
    display: 'inline-block',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '15px',
    width: '15px',
  },
  '&:hover': {
    backgroundColor: 'transparent',
    color: "#1de9b6",
    transform: 'translateX(20px)',
    pl: '15px',
    '&:after': {
      transform: 'translateX(0%)',
    },
    '&::before': {
      opacity: 1,
      transform: 'translateX(0%)'
    },
  },
  '&:not(:hover)::after': {
    transform: 'translateX(-105%)',
  }
}

export const Footer = () => {

  const { currentLang } = useContext(UiContext)
  const { navigate } = useNavegate()

  return (
    <Box component='footer' className='WtaFooter'>
      <Box sx={{
        py: '20px',
        px: { xs: '1rem', md: '0' },
      }}>

        <Stack
          spacing={{ xs: 1, sm: 2, md: 0 }}
          direction={{xs: "column", sm: "row"}}
          useFlexGap
          sx={{ flexWrap: 'wrap' }}
        >
          {/* Logo background */}
          <Item elevation={0}>
            <Box component='figure' className='footer-logo'>
              <img loading='lazy'  width="167px" height="148px" src={getImageURL('logo_footer_A1.png')} alt="Logo footer" />
            </Box>
          </Item>
          {/* Logo  */}
          <Item
            elevation={0}
            sx={{
              maxWidth: '300px',
            }}>
            <Box component='figure' className='img-logo'>
              <img loading='lazy' height='184px' width='65px' src={getImageURL('logo_nav_white.png')} alt="Logo de WTA" />
            </Box>
            <Typography variant="body1"
              sx={{
                color: '#fff',
                fontSize: '15px',
                mt: '20px',
                width: '230px',
              }}>
              {textFooter[currentLang].paragraph}
            </Typography>
          </Item>
          {/* Link contacts */}
          <Item elevation={0}>

            <Typography variant="body1" sx={{
              color: '#fff',
              fontSize: '18px',
              width: { xs: '320px', md: '400px', },
            }}>
              <LocationOnIcon sx={{
                marginRight: '10px',
              }} />
              {textFooter[currentLang].location}
            </Typography>
            {
              contactLinks.map(item => (
                <Box
                  key={item.text}
                  sx={{
                    display: 'flex',
                    width: { xs: '320px', md: '400px', },
                  }}>
                  {item.icon}
                  <Link
                    href={item.link}
                    underline="none"
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#fff',
                        fontSize: '18px',
                        '&:hover': {
                          color: "#1de9b6",
                        },
                        ml: '10px',
                      }}>
                      {item.text}
                    </Typography>
                  </Link>
                </Box>
              )
              )
            }
          </Item>
          {/* Divisor */}
          <Divider orientation="vertical" variant="middle" flexItem
            sx={{
              borderWidth: '1px',
              borderColor: '#1DE9B6',
              margin: '19px 0',
              display: { xs: 'none', md: 'block' }
            }}
          />
          <Item
            elevation={0}
            sx={{
              marginLeft: { xs: 0, md: '70px' },
              width: '300px',
            }}>
            {
              pages[currentLang].map((page) => (
                <Button
                  key={page.title}
                  onClick={() => navigate(page.url)}
                  className='footerButton'
                  sx={pageLinksStyles}>
                  {page.title}
                </Button>
              ))
            }
          </Item>

          <Item
            elevation={0}
            sx={{
              mx: 'auto',
              px: '10%'
            }}>
            <Typography variant="body1" sx={{
              fontSize: '15px',
              color: '#fff',
              textAlign: 'center',
            }}>
              {textFooter[currentLang].paragraphTwo}
            </Typography>
          </Item>
          <Item
            elevation={0}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mx: 'auto'
            }}>
            {
              textFooter[currentLang].documentLinks.map((item, index) => (
                <React.Fragment key={item.text}>
                  <Typography
                    variant='span'
                    {...(index < textFooter[currentLang].documentLinks.length - 1 ? { onClick: () =>  window.open(`${window.location.origin + import.meta.env.VITE_BASE_NAME + item.url}`, "_blank") } : {})}                    
                    id={item?.id}
                    sx={{
                      color: '#fff',
                      cursor: 'pointer',
                      '&:hover': {
                        color: "#1de9b6",
                      },
                    }}>
                    {item.text}
                  </Typography>
                  {index < textFooter[currentLang].documentLinks.length - 1 && (
                    <Divider orientation="vertical" variant="middle" flexItem
                      sx={{
                        borderWidth: '1px',
                        borderColor: '#fff',
                        height: '10px',
                        mx: '15px',
                        display: 'inline-block'
                      }}
                    />
                  )}
                </React.Fragment>
              ))
            }
          </Item>
        </Stack>
      </Box>
    </Box>
  )
}
