import { Stack, Typography, Card, CardMedia, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useContext } from 'react';

import GrupoHasVideo from '../../assets/videos/grupoHasVideo.mp4'
import { UiContext } from '../../context';
import { languagesTypes } from '../../types/types';


const textPresence = {
  [languagesTypes.english]: {
    title: 'Our Global Presence',
    paragraphOne: 'Explore our 10 offices and contact centers strategically located around the world.',
    paragraphTwo: 'At WTA by HAS, we provide a broad portfolio of products for international clients. Currently, more than 100 companies worldwide rely on our services. With the support of more than 600,000 suppliers, we ensure exceptional worldwide coverage.'
  },
  [languagesTypes.spanish]: {
    title: 'Nuestra presencia global',
    paragraphOne: 'Explora nuestras 10 oficinas y contact centers ubicados estratégicamente en todo el mundo.',
    paragraphTwo: 'En WTA by HAS, brindamos un amplio portafolio de productos para clientes internacionales. Actualmente, más de 100 compañías a nivel global confían en nuestros servicios. Con el respaldo de más de 600.000 proveedores, aseguramos una cobertura mundial excepcional.'
  },
  [languagesTypes.portugues]: {
    title: 'Nossa presença Global',
    paragraphOne: 'Conheça os nossos 10 escritórios e centros de contacto estrategicamente localizados em todo o mundo.',
    paragraphTwo: 'Na WTA by HAS, oferecemos uma vasta gama de produtos para clientes internacionais. Atualmente, mais de 100 empresas em todo o mundo confiam nos nossos serviços. Com o apoio de mais de 600.000 fornecedores, asseguramos uma cobertura mundial excecional.'
  }

}

export const OurPresence = () => {

  const { currentLang } = useContext(UiContext);

  return (
    <Box px='10%' py='5%' component='section'>
      <Grid container spacing={{xs: 3, lg: 6}} >
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack spacing={3} sx={{
            borderTop: '1px solid #0e3674',
            py: '16px',
            textAlign: 'justify'
          }}>
            <Typography variant='h3' sx={{
              textAlign: 'center',
              marginTop: '14px',
            }}>
              {textPresence[currentLang].title}
            </Typography>
            <Typography variant='body1' sx={{
              fontWeight: '700',
            }} >
              {textPresence[currentLang].paragraphOne}
            </Typography>
            <Typography variant='body1'>
              {textPresence[currentLang].paragraphTwo}
            </Typography>
          </Stack>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }} >
          <Card
            sx={{
              width: '100%',
              height: '110',
              minWidth: 275,
              border: '15px solid #0d47a1',
              borderRadius: '2rem',
              padding: 0,
            }}>

            <CardMedia
              sx={{
                height: '100%',
                width: '100%',
                borderRadius: '1px',
              }}
              component="video"
              image={GrupoHasVideo}
              title='Video de grupo has'
              controls
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

