import { Box, Typography, Divider } from "@mui/material"
import { getImageURL } from "../../helpers"
import { useContext } from "react";

import { UiContext } from "../../context";
import { languagesTypes } from "../../types/types";

const integralServices = {
    [languagesTypes.english]: {
        title: "Integral service",
        text: 'Our mission is to support our clients anywhere in the world, in any situation, every step of the way.',
        text2: 'We establish direct and close bonds that reflect our corporate values: security and trust.',
        text3: 'We are here to provide benefits and optimized service when our clients’ customers need it most.'
    },

    [languagesTypes.spanish]: {
        title: "Servicio integral",
        text: 'Acompañamos a nuestros clientes en cada situación, en cada momento y en cualquier lugar del mundo.Nuestra misión es estar a su lado siempre.',
        text2: 'Creamos vínculos directos y cercanos que reflejan algunos de nuestros valores corporativos: seguridad y confianza.',
        text3: 'Cuando más lo necesitan, estamos aquí para sus clientes, generando valor y optimizando cada aspecto de nuestro servicio.'
    },
    [languagesTypes.portugues]: {
        title: "Serviço integral",
        text: 'Acompanhamos os nossos clientes em todas as situações, em cada momento e em qualquer lugar do mundo.Nossa missão é estar sempre ao seu lado.',
        text2: 'Criamos laços diretos e próximos que refletem alguns dos nossos valores corporativos: segurança e confiança.',
        text3: 'Quando você mais precisa, estamos aqui para seus clientes, gerando valor e otimizando todos os aspectos de nosso atendimento.'
    }
}

export const OurIntegralService = () => {

    const { currentLang } = useContext(UiContext)

    return (
        <Box component="section"
            sx={{
                backgroundImage: `url(${getImageURL('servicio_integral.webp')})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                py: '50px',
                px: { xs: '20px', md: '100px' }
            }}
        >
            <Box
                sx={{
                    width: { xs: '100%', md: '50%' },
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '7%',
                    gap: '0.3rem'
                }}
            >
                <Divider orientation="vertical" variant="middle" flexItem
                    sx={{
                        borderWidth: '1px',
                        borderColor: '#fff',
                        margin: '19px 0',
                    }}
                />
                <Typography variant="h4"
                    sx={{
                        color: '#fff'
                    }}
                >
                    {integralServices[currentLang].title}
                </Typography>
                <Typography variant="body1"
                    sx={{
                        color: '#fff',
                        fontSize: '22px',
                        textAlign: 'justify'
                    }}
                >
                    {integralServices[currentLang].text}
                </Typography>
                <Typography variant="body1"
                    sx={{
                        color: '#fff',
                        fontSize: '22px',
                        textAlign: 'justify',
                        mt: '15px'
                    }}
                >
                    {integralServices[currentLang].text2}
                </Typography>
                <Typography variant="body1"
                    sx={{
                        color: '#fff',
                        fontSize: '22px',
                        textAlign: 'justify',

                    }}>
                    {integralServices[currentLang].text3}
                </Typography>
            </Box>

        </Box>
    )
}
