import { PrincipalBanner, StadisticsNumbers, WhoAreWe, OurPresence, OurLocations, OurAssistance, OurTeam, Certifications, OurCoverage, SupplierNetwork, OurIntegralService, Carousel } from "../../components"
import { getImageURL } from "../../helpers"

export const AboutUsPage = () => {
  return (
    <>
      <PrincipalBanner
        title="Specializing in risk management and travelers' assistance administration."
        imgPath={getImageURL('banner_principal.webp')} />
      <WhoAreWe />
      <StadisticsNumbers />
      <OurPresence />
      <OurLocations />
      <OurTeam />
      <OurCoverage />
      <OurIntegralService />
      <OurAssistance />
      <SupplierNetwork />
      <Certifications />
      <Carousel />
    </>
  )
}
