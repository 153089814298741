import { Outlet } from 'react-router-dom'
import { NavBar, Footer } from '../../components'
import ScrollToTop from '../../helpers/ScrollToTop'
import ScriptLoader from '../../helpers/ScriptLoader'

export const EnglishRoot = () => {
  return (
    <>
      <ScrollToTop />
      <ScriptLoader />
      <NavBar />
      <Outlet />
      <Footer />
    </>
  )
}
