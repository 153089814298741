import PropTypes from 'prop-types'
import { ThemeProvider } from '@emotion/react'
import { customTheme } from './customTheme'

export const AppTheme = ({ children }) => {
    return (
        <ThemeProvider theme={customTheme}>
            {children}
        </ThemeProvider>
    )
}

AppTheme.propTypes = {
    children: PropTypes.node.isRequired,
}
