import { Typography, Box } from '@mui/material';
import { useContext } from 'react';

import { languagesTypes } from '../../types/types';
import { UiContext } from '../../context';

const whoAreWeInfo = {
  [languagesTypes.english]: {
    title: 'WHO ARE WE?',
    text1: 'We are a North American company, part of the HAS Group, with more than 24 years of experience specializing in travel assistance, risk management (TPA) and cost containment.',
    text2: 'Our quality is closely linked to the effective and efficient work we do together with our clients. We develop customized, flexible and innovative products, becoming a strategic ally focused on providing solutions.'
  },
  [languagesTypes.spanish]: {
    title: 'QUIÉNES SOMOS',
    text1: 'Somos una compañía norteamericana, que hace parte del grupo HAS, con más de 24 años de experiencia especializada en la asistencia en viajes, la gestión de riesgos (TPA) y contención de costos.',
    text2: 'Nuestra calidad esta estrechamente ligada a la labor efectiva y eficiente que realizamos junto a nuestros clientes. Desarrollamos productos personalizados, flexibles e innovadores, convirtiéndonos en un aliado estratégico enfocado en brindar soluciones.'
  },
  [languagesTypes.portugues]: {
    title: 'QUEM SOMOS',
    text1: 'Somos uma empresa norte-americana, pertencente ao grupo HAS, com mais de 24 anos de experiência, especializada em assistência em viagem, gestão de riscos (TPA) e contenção de custos.',
    text2: 'A nossa qualidade está intimamente ligada ao trabalho eficaz e eficiente que desenvolvemos em conjunto com os nossos clientes. Desenvolvemos produtos personalizados, flexíveis e inovadores, o que faz de nós um parceiro estratégico e orientado para a oferta de soluções.'
  }
}

export const WhoAreWe = () => {

  const { currentLang } = useContext(UiContext)

  return (
    <Box component='section'>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: { xs: '20px', md: '100px' },
        py: { xs: '20px', md: '3rem' }
      }}>
        <Typography variant="h2" sx={{
          pb: '.5rem',
          fontSize: '40px',
          fontWeight: 'bold',
          color: '#53E0B4',
          '@media (max-width:600px)': {
            fontSize: '30px',
          },
        }}>
          {whoAreWeInfo[currentLang].title}
        </Typography>
        <Typography variant="body1" sx={{
          mb: '1rem'
        }}>
          {whoAreWeInfo[currentLang].text1}
        </Typography>
        <Typography variant="body1">
          {whoAreWeInfo[currentLang].text2}
        </Typography>
      </Box>
    </Box>
  )
}
