export const languagesTypes = {
    spanish: 'Español',
    english: 'English',
    portugues: 'Português'
}

export const infoLanguages = [
    {
        idioma: languagesTypes.spanish,
        url: '/sobre-nosotros'
    },
    {
        idioma: languagesTypes.english,
        url: '/about-us'
    },
    {
        idioma: languagesTypes.portugues,
        url: '/sobre-nos'
    },
]