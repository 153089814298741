import { Typography, styled, Box, Paper } from '@mui/material';
import PropTypes from 'prop-types'
import CountUp from 'react-countup';
import { useState, useEffect, useRef } from 'react';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: '#fff',
    flexGrow: 1,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
}));



export const StadisticCard = ({ statImg, number, text, decimals, decimal, suffix, index }) => {

    const [inView, setInView] = useState(false);

    // Ref para el componente
    const cardRef = useRef(null);

    // Función para manejar el cambio de visibilidad
    const handleIntersection = (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
            setInView(true);  // Cuando la sección es visible
        }
    };

    useEffect(() => {

        // Crear un observer para verificar la visibilidad
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5,  // Se activará cuando el 50% del componente sea visible
        });

        // Observar el elemento
        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        // Limpiar el observer cuando el componente se desmonte
        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, []);


    return (

        <Item component='section' elevation={0} ref={cardRef} sx={{
            minWidth: '150px',
            width: '170px',
            height: { xs: 'auto', md: '300px' },
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Box component='figure' sx={{
                flexGrow: 1,

            }}>
                <Box component='img' loading='lazy' src={statImg} alt="Stat 1" sx={{ width: '70px', height: '70px' }} />
            </Box>
            <Box
                sx={{
                    opacity: inView ? 1 : 0,
                    flexGrow: 7,
                    animation: inView ? `${index + 1.5}s anim-lineUp ease-out` : 'none',
                }}  
            >

                <Typography variant="h3"
                    sx={{
                        color: '#1de9b6',
                        fontWeight: 'bold',
                        fontSize: '26px',
                        textAlign: 'center'
                    }}
                >
                    +
                    {inView && (
                        <CountUp redraw={true} separator='.' decimals={decimals} duration={index + 2.5} decimal={decimal} end={number} />
                    )}
                    {suffix}
                </Typography>
                <Typography variant="body1"
                    sx={{
                        color: '#fff',
                        fontWeight: '700',
                        textAlign: 'center',
                        fontSize: '1rem'
                    }}
                >
                    {text}
                </Typography>
            </Box>
        </Item>
    )
}

StadisticCard.propTypes = {
    statImg: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    decimals: PropTypes.number,
    decimal: PropTypes.string,
    index: PropTypes.number
}