import PropTypes from 'prop-types'
import { UiContext } from './UiContext'
import { useLastVisit } from '../hooks/useLang'
import { useMemo } from 'react'

export const UiProvider = ({ children }) => {

  const { currentLang, changeLang, } = useLastVisit()

  const providerValues = useMemo(() => ({ currentLang, changeLang }), [currentLang, changeLang])

  return (
    <UiContext.Provider value={providerValues}>
      {children}
    </UiContext.Provider>
  )
}

UiProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
