import { CCarousel, CCarouselItem, CImage } from '@coreui/react';
import { getImageURL } from '../../helpers';
import { Box, Typography } from '@mui/material';
import '@coreui/coreui/dist/css/coreui.min.css'
import { languagesTypes } from '../../types/types';
import { useContext } from 'react';
import { UiContext } from '../../context';

const companyLogos = [
    [
        {
            img: "aig.avif",
            height: '60px',
            width: '100px'
        },
        {
            img: "american_life.avif",
            height: '60px',
            width: '120px'
        },
        {
            img: "aura_assis.avif",
            height: '50px',
            width: '150px'
        },
        {
            img: "aurora_sa.avif",
            height: '80px',
            width: '100px'
        },
        {
            img: "chubb.avif",
            height: '30px',
            width: '150px'
        },
    ],
    [
        {
            img: "heritage_heal.avif",
            height: '100px',
            width: '100px'
        },
        {
            img: "india_assur.avif",
            height: '100px',
            width: '100px'
        },
        {
            img: "inter_mg.avif",
            height: '80px',
            width: '150px'
        },
        {
            img: "logo_axa.avif",
            height: '90px',
            width: '150px'
        },
        {
            img: "logo_bmi.avif",
            height: '90px',
            width: '160px'
        },
    ],
    [
        {
            img: "logo_national.avif",
            height: '100px',
            width: '150px'
        },
        {
            img: "logo_sbs.avif",
            height: '100px',
            width: '150px'
        },
        {
            img: "logo_zurich.avif",
            height: '100px',
            width: '190px'
        },
        {
            img: "meridio_seg.avif",
            height: '30px',
            width: '150px'
        },
        {
            img: "oriental_ins.avif",
            height: '70px',
            width: '150px'
        },
    ],
    [
        {
            img: "prestige_assu.avif",
            height: '60px',
            width: '200px'
        },
        {
            img: "sancor_seg.avif",
            height: '70px',
            width: '150px'
        },
        {
            img: "united_india.avif",
            height: '100px',
            width: '100px'
        },
    ]
];

const companyLogosMobile = [
    {
        img: "aig.avif",
        height: '60px',
        width: '100px'
    },
    {
        img: "american_life.avif",
        height: '60px',
        width: '120px'
    },
    {
        img: "aura_assis.avif",
        height: '50px',
        width: '150px'
    },
    {
        img: "aurora_sa.avif",
        height: '80px',
        width: '100px'
    },
    {
        img: "chubb.avif",
        height: '30px',
        width: '150px'
    },
    {
        img: "heritage_heal.avif",
        height: '100px',
        width: '100px'
    },
    {
        img: "india_assur.avif",
        height: '100px',
        width: '100px'
    },
    {
        img: "inter_mg.avif",
        height: '80px',
        width: '150px'
    },
    {
        img: "logo_axa.avif",
        height: '90px',
        width: '150px'
    },
    {
        img: "logo_bmi.avif",
        height: '90px',
        width: '160px'
    },
    {
        img: "logo_national.avif",
        height: '100px',
        width: '150px'
    },
    {
        img: "logo_sbs.avif",
        height: '100px',
        width: '150px'
    },
    {
        img: "logo_zurich.avif",
        height: '100px',
        width: '190px'
    },
    {
        img: "meridio_seg.avif",
        height: '30px',
        width: '150px'
    },
    {
        img: "oriental_ins.avif",
        height: '70px',
        width: '150px'
    },
    {
        img: "prestige_assu.avif",
        height: '60px',
        width: '200px'
    },
    {
        img: "sancor_seg.avif",
        height: '70px',
        width: '150px'
    },
    {
        img: "united_india.avif",
        height: '100px',
        width: '100px'
    },

]


const carousel = {
    [languagesTypes.english]: 'STRATEGIC ALLIES',
    [languagesTypes.spanish]: 'ALIADOS ESTRATÉGICOS.',
    [languagesTypes.portugues]: 'ALIADOS ESTRATÉGICOS'
}

export const Carousel = () => {

    const { currentLang } = useContext(UiContext);


    return (
        <Box component='section' backgroundColor='#fff'>
            <Box>
            <Typography variant='h5'
                    sx={{
                        color: '#fff',
                        background: 'linear-gradient(90deg, #376ab0 10%, #428faa 35%, #48a1a3 55%, #53c095 80%)',
                        width: { xs: '90%', md: '43rem' },
                        textAlign: 'center',
                        p: '0.20rem 2rem',
                        borderRadius: '70px',
                        fontSize: { xs: '0.8rem', md: '1.5rem' },
                        margin: '0 auto'
                    }}
                >
                    {carousel[currentLang]}
                </Typography>
                <Box sx={{
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'center',
                    padding: '5rem 0',
                }}>
                    <CCarousel controls interval={3000} className='crs-container'>
                        {
                            companyLogos.map((companies, index) => (
                                <CCarouselItem
                                    key={`Item-${index}`}>
                                    {
                                        companies.map(company => (
                                            <CImage
                                                key={`imageItem-${company.img}`}
                                                className="display-block"
                                                loading='lazy'
                                                height={company.height}
                                                width={company.width}
                                                src={getImageURL(`companies/${company.img}`)}
                                                alt={`Slide ${index}`}
                                            />
                                        ))
                                    }
                                </CCarouselItem>
                            ))
                        }
                    </CCarousel>
                </Box>
                <Box sx={{
                    display: { xs: 'block', md: 'none' },
                    py: { xs: '2rem' }
                }}>
                    <CCarousel controls className='crs-container' interval={3000}>
                        {
                            companyLogosMobile.map(company => (
                                <CCarouselItem
                                    key={`Item-${company.img}`}
                                >
                                    <CImage
                                        key={`imageItem-${company.img}`}
                                        loading='lazy'
                                        className="display-block"
                                        height={company.height}
                                        width={company.width}
                                        src={getImageURL(`companies/${company.img}`)}
                                        alt={`Slide ${company.img}`}
                                    />
                                </CCarouselItem>
                            ))
                        }
                    </CCarousel>
                </Box>
            </Box>
        </Box>
    )
}
