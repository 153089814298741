import { Box, Fade, useScrollTrigger } from "@mui/material"
import PropTypes from 'prop-types';

export const ScrollTop = ({ children, }) => {

    const trigger = useScrollTrigger();

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#ScrolltoTop',
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16}}
            >
                {children}
            </Box>
        </Fade>
    )
}

ScrollTop.propTypes = {
    children: PropTypes.node.isRequired,
}