import { englishRoutes } from '../ingles/router/routes';
import { spanishRoutes } from '../español/router/routes';
import { portugueseRoutes } from '../portugues/router/routes';
import { languagesTypes } from '../types/types';

export const getLangByUrl = (url) => {
    // Obtiene el path final de la url
    // Ejemplo: http://localhost:3000/sobre-nosotros
    // Obtiene solo "sobre-nosotros"
    const urlPath = url.substring(url.lastIndexOf('/') + 1);

    if (englishRoutes.find((route) => route.path === urlPath)) return languagesTypes.english;
    if (spanishRoutes.find((route) => route.path === urlPath)) return languagesTypes.spanish;
    if (portugueseRoutes.find((route) => route.path === urlPath)) return languagesTypes.portugues;

    return languagesTypes.english;
}