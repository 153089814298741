import { IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types'

export const NavBarMobile = ({ handleOpenMenu }) => {
  
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'flex', md: 'none' },
        justifyContent: 'end'
      }}>
      <IconButton
        onClick={() => {
          handleOpenMenu()
        }}
        aria-label="Toogle"
      >
        <MenuIcon
          sx={{
            display: { xs: 'flex', md: 'none', color: 'black', fontSize: '2rem' },
          }}
        />
      </IconButton>
    </Box>
  )
}

NavBarMobile.propTypes = {
  handleOpenMenu: PropTypes.func
}
