import { Box, Stack, Divider } from '@mui/material';
import { useContext } from 'react';

import { StadisticCard } from './components';
import { languagesTypes } from '../../types/types';
import { UiContext } from '../../context';
import { getImageURL } from '../../helpers';


export const StadisticsNumbers = () => {

  const stats = {
    [languagesTypes.english]: [
      {
        statImg: getImageURL('icon_esta5.png'),
        number: 24,
        text: 'YEARS IN THE MARKET'
      },
      {
        statImg: getImageURL('icon_esta3.png'),
        number: 120,
        text: 'COMPANIES TRUST US'
      },
      {
        statImg: getImageURL('servicios.svg'),
        number: 850000,
        text: 'SERVICES PROVIDED PER YEAR'
      },
      {
        statImg: getImageURL('Colaboradores_icono.png'),
        number: 700,
        text: 'COWORKERS'
      },
      {
        statImg: getImageURL('comunicaciones.svg'),
        number: 1.6,
        suffix: 'M',
        decimals: 1,
        decimal: '.',
        text: 'MILLION CONTACT CENTER COMMUNICATIONS PER YEAR'
      },
      {
        statImg: getImageURL('proovedores.svg'),
        number: 600000,
        text: 'SUPPLIERS WORLDWIDE'
      },
      {
        statImg: getImageURL('desarrollos.svg'),
        number: 40,
        text: 'IT DEVELOPMENTS IN THE ASSISTANCE SECTOR'
      },
    ],
    [languagesTypes.spanish]: [
      {
        statImg: getImageURL('icon_esta5.png'),
        number: 24,
        text: 'AÑOS EN EL MERCADO'
      },
      {
        statImg: getImageURL('icon_esta3.png'),
        number: 120,
        text: 'COMPAÑÍAS CONFÍAN EN NOSOTROS'
      },
      {
        statImg: getImageURL('servicios.svg'),
        number: 850000,
        text: 'SERVICIOS PRESTADOS AL AÑO'
      },
      {
        statImg: getImageURL('Colaboradores_icono.png'),
        number: 700,
        text: 'COLABORADORES'
      },
      {
        statImg: getImageURL('comunicaciones.svg'),
        number: 1.6,
        suffix: 'M',
        decimals: 1,
        decimal: '.',
        text: 'MILLONES DE COMUNICACIONES AL CONTACT CENTER POR AÑO'
      },
      {
        statImg: getImageURL('proovedores.svg'),
        number: 600000,
        text: 'PROVEEDORES A NIVEL MUNDIAL'
      },
      {
        statImg: getImageURL('desarrollos.svg'),
        number: 40,
        text: 'DESARROLLOS INFORMÁTICOS EN EL SECTOR DE ASISTENCIAS'
      },
    ],
    [languagesTypes.portugues]: [
      {
        statImg: getImageURL('icon_esta5.png'),
        number: 24,
        text: 'DE ANOS NO MERCADO'
      },
      {
        statImg: getImageURL('icon_esta3.png'),
        number: 120,
        text: 'COMPANHIAS CONFIAMEM NÓS'
      },
      {
        statImg: getImageURL('servicios.svg'),
        number: 850000,
        text: 'SERVIÇOS PRESTADOS POR ANO'
      },
      {
        statImg: getImageURL('Colaboradores_icono.png'),
        number: 700,
        text: 'COLABORADORES'
      },
      {
        statImg: getImageURL('comunicaciones.svg'),
        number: 1.6,
        suffix: 'M',
        decimals: 1,
        decimal: '.',
        text: 'MILHÕES DE CHAMADASPARA CENTRAL DE CONTATO POR ANO'
      },
      {
        statImg: getImageURL('proovedores.svg'),
        number: 600000,
        text: 'PROVEDORES A NÍVEL MUNDIAL'
      },
      {
        statImg: getImageURL('desarrollos.svg'),
        number: 40,
        text: 'DESENVOLVIMENTOS INFORMÁTICOS NO SETOR DE ASSISTÊNCIAS'
      },
    ],
  };

  const { currentLang } = useContext(UiContext)

  return (
    <Box component='section'
      sx={{
        backgroundColor: '#0d47a1',
        padding: '20px 20px',
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
        direction={{
          xs: 'column', md: 'row'
        }}
        divider={<Divider orientation="vertical" variant="middle" flexItem
          sx={{
            borderColor: '#1DE9B6',
            mt: '65px',
            display: { xs: 'none', md: 'block' },
          }}
        />}>
        {stats[currentLang].map((stat, index) => (
          <StadisticCard
            key={stat.text}
            statImg={stat.statImg}
            number={stat.number}
            text={stat.text}
            suffix={stat.suffix}
            decimals={stat.decimals}
            decimal={stat.decimal}
            index={index}
          />
        ))}

      </Stack>
    </Box>
  )
}
