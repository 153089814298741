import { Divider, Avatar, IconButton, Box, MenuItem, Typography, Menu, Tooltip, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types'
import React, { useContext } from 'react';

import TranslateSvg from '../assets/images/traductor.svg'
import { UiContext } from '../context';
import { languagesTypes } from '../types/types';
import { useNavegate } from '../hooks/useNavegate';


export const NavBarDesktop = ({ pages, anchorLanguageDropDown, handleOpenLanguageMenu, setAnchorLanguageDropDown, handleLanguage }) => {

  const languages = Object.values(languagesTypes)

  const {navigate} = useNavegate();

  const handleCloseLanguageMenu = () => {
    setAnchorLanguageDropDown(null);
  }

  const { currentLang } = useContext(UiContext)

  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
      {pages.map((page, index) => (
        <React.Fragment key={page.title + 'Nav bar'}>
          <Button
            onClick={() => navigate(page.url)}
            sx={{
              my: 2,
              fontSize: '16px',
              fontWeight: 400,
              color: 'black',
              display: 'block',
              '&:hover': {
                backgroundColor: 'transparent',
                color: "#345DB2",
              }
            }}>
            {page.title}
          </Button>
          {index < pages.length - 1 && (
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderWidth: '1px',
                borderColor: '#3152A9',
                margin: '28px 0',
              }}
            />
          )}
        </React.Fragment>
      ))}
      <Box sx={{ flexGrow: 0, alignSelf: 'center', display: 'flex' }}>
        <Tooltip title="Select Language">
          <IconButton onClick={handleOpenLanguageMenu} aria-label='select language' sx={{ p: 0 }}>
            <Avatar alt="Globo terráqueo" src={TranslateSvg} />
            <KeyboardArrowDownIcon sx={{
              color: 'black',
              alignSelf: 'center'
            }} />
          </IconButton>
        </Tooltip>

        {/* Dropdown languages */}
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorLanguageDropDown}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorLanguageDropDown)}
          onClose={handleCloseLanguageMenu}
        >
          {languages.filter(lang => lang != currentLang).map((lang) => (
            <MenuItem
              key={lang}
              selected={lang === currentLang}
              onClick={() => handleLanguage(lang)}
              sx={{
                fontSize: '16px',
                color: 'black',
              }}
            >
              <Typography variant='h6'
                sx={{
                  textAlign: 'center',
                  fontWeight: 400,
                }}>
                {lang}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  )
}

NavBarDesktop.propTypes = {
  pages: PropTypes.array.isRequired,
  anchorLanguageDropDown: PropTypes.any,
  handleLanguage: PropTypes.func.isRequired,
  handleOpenLanguageMenu: PropTypes.func.isRequired,
  setAnchorLanguageDropDown: PropTypes.func.isRequired,
}