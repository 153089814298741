import { Divider, Box, Typography, Stack, styled, Paper } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useContext } from 'react';


import background from '../../assets/images/red_proveedores.webp'
import { UiContext } from '../../context';
import { languagesTypes } from '../../types/types';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: '#0A3470',
    flexGrow: 1,
    backgroundColor: 'transparent',
}));

const PermanentAssistanceInfo = {
    [languagesTypes.english]: {
        title: 'Supplier network',
        text1: 'We have a network of specialists, medical centers, hospitals, air, and ground ambulance services, dentists, funeral homes, among others.',
        text2: 'Our provider department has multilingual representatives, available 24/7 and specializing in research, management, and control of the network.',
        title2: 'A network of experts capable of rising to the occasion and assisting travelers on an ongoing basis.',
        items: [
            "General medicine in the doctor's office and at home.",
            'Various medical specialties.',
            'Clinics, medical centers, and hospitals.',
            'Air and ground ambulance services.',
            'Helicopter rescues.',
            'Funeral homes.',
            'Rescues in critical situations.',
            'Veterinary services.',
            'Home assistance.',
            'Roadside assistance.'
        ],
        text4: 'At World Travel Assist we work responsibly to manage suppliers for any type of service required.',
        text5: 'We ensure greater territorial access for the management of the direct global network.'
    },
    [languagesTypes.spanish]: {
        title: 'Red de proveedores',
        text1: 'Contamos con una red de especialistas, centros médicos, hospitales, ambulancias aéreas y terrestres, odontólogos, funerarias, entre otros.',
        text2: 'Nuestro departamento de proveedores tiene representantes multilingües, disponibles 24/7 y especializados en la búsqueda, gestión y control de la red.',
        title2: 'Una red de expertos capaces de estar a la altura de las circunstancias y asistir al viajero en forma constante.',
        items: [
            "Medicina general en consultorio médico y a domicilio.",
            'Diferentes especialidades médicas.',
            'Clínicas, centros médicos y hospitales.',
            'Aeroambulancias y ambulancias terrestres.',
            'Rescates aéreos en helicóptero.',
            'Funerarias.',
            'Rescates en situaciones críticas.',
            'Servicios veterinarios.',
            'Asistencia al hogar.',
            'Asistencia en carretera.'
        ],
        text4: 'En World Travel Assist trabajamos con responsabilidad para gestionar proveedores ante cualquier tipo de servicio que sea requerido.',
        text5: 'Aseguramos un mayor acceso territorial para la gestión de la red mundial directa.'
    },
    [languagesTypes.portugues]: {
        title: 'Rede de fornecedores',
        text1: 'Contamos com uma rede de especialistas, centros médicos, hospitais, ambulâncias aéreas e terrestres, dentistas, funerárias, entre outros.',
        text2: 'Nosso departamento de fornecedores conta com representantes multilíngues, disponíveis 24 horas por dia, 7 dias por semana e especializados em busca, gerenciamento e controle de redes.',
        title2: 'Uma rede de especialistas capazes de estar à altura da situação e ajudar os viajantes constantemente.',
        items: [
            "Medicina geral no consultório médico e em domicílio.",
            'Diferentes especialidades médicas.',
            'Clínicas, centros médicos e hospitais.',
            'Ambulâncias aéreas e ambulâncias terrestres.',
            'Resgates aéreos de helicóptero.',
            'Funerárias.',
            'Resgates em situações críticas.',
            'Serviços veterinários.',
            'Assistência domiciliar.',
            'Assistência rodoviária.'
        ],
        text4: 'En World Travel Assist trabalhamos com responsabilidade para gerenciar provedores antes de qualquer tipo de serviço que seja necessário.',
        text5: 'Garantimos maior acesso territorial para a gestão da rede global direta.'
    }
}

export const SupplierNetwork = () => {

    const { currentLang } = useContext(UiContext)

    return (
        <Box component='section'
            sx={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                padding: '7% 1rem'
            }}
        >
            <Box
                sx={{
                    width: { xs: '100%', md: '50%' }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        px: { xs: '0', md: '6rem' }
                    }}
                >
                    <Divider orientation="vertical" variant="middle" flexItem
                        sx={{
                            borderWidth: '1px',
                            borderColor: '#0D47A1',
                            display: 'block'
                        }}
                    />
                    <Typography variant='h4'
                        sx={{
                            fontSize: '23px',
                            fontWeight: 'bold',
                            color: '#0a3470',
                            paddingBottom: '0.5rem'
                        }}
                    >
                        {PermanentAssistanceInfo[currentLang].title}
                    </Typography>
                    <Typography variant='body1'
                        sx={{
                            paddingBottom: '0.5rem',
                            textAlign: 'justify'
                        }}
                    >
                        {PermanentAssistanceInfo[currentLang].text1}
                    </Typography>
                    <Typography variant='body1'
                        sx={{
                            textAlign: 'justify'
                        }}
                    >
                        {PermanentAssistanceInfo[currentLang].text2}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    width: { xs: '100%', md: '50%' }
                }}
            >
                <Box>
                    <Typography variant='body1'
                        sx={{
                            paddingBottom: '0.5rem'
                        }}
                    >
                        {PermanentAssistanceInfo[currentLang].title2}
                    </Typography>
                    <Stack component='div'
                        sx={{
                            padding: '0'
                        }}
                    >
                        {PermanentAssistanceInfo[currentLang].items.map(item => (
                            <Item
                                elevation={0}
                                key={item}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '12px',
                                    padding: '0'
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{
                                        width: '12px',
                                        height: '12px'
                                    }}
                                />
                                <Typography variant='body1'
                                    sx={{
                                        fontSize: '22px'
                                    }}
                                >
                                    {item}
                                </Typography>
                            </Item>
                        ))}
                    </Stack>
                    <Typography variant='body1'
                        sx={{
                            fontSize: '22px',
                            paddingTop: '0.5rem',
                        }}
                    >
                        {PermanentAssistanceInfo[currentLang].text4}
                    </Typography>
                    <Typography variant='body1'
                        sx={{
                            fontSize: '22px',
                            paddingTop: '1rem'
                        }}
                    >
                        {PermanentAssistanceInfo[currentLang].text5}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
