import { createTheme } from '@mui/material';

const mainColor = '#0A3470'
const secondaryColor = '#53E0B4'

export const customTheme = createTheme({
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h3: {
            fontSize: '40px',
            fontWeight: 'bold',
            color: secondaryColor,
            '@media (max-width:600px)': {
                fontSize: '30px',
            },
        },
        body1: {
            fontSize: '25px',
            color: mainColor,
            '@media (max-width:600px)': {
                fontSize: '20px',
            },
        },
    },
    palette: {
        primary: {
            main: mainColor,
        },
        secondary: {
            main: secondaryColor,
        },
    }
})
