import { Outlet } from "react-router-dom"
import { Footer, NavBar } from '../../components'
import ScrollToTop from "../../helpers/ScrollToTop"
import ScriptLoader from "../../helpers/ScriptLoader"

export const PortugueseRoot = () => {
  return (
    <>
      <ScrollToTop />
      <ScriptLoader />
      <NavBar />
      <Outlet />
      <Footer />
    </>
  )
}
