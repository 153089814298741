import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { SpanishRoot, spanishRoutes } from '../español';
import { EnglishRoot, englishRoutes } from '../ingles';
import { PortugueseRoot, portugueseRoutes } from '../portugues';

export const AppRouter = () => {


  const router = createBrowserRouter([
    {
      path: '/',
      element: <EnglishRoot />,
      children: englishRoutes
    },
    {
      path: '/',
      element: <SpanishRoot />,
      children: spanishRoutes
    },
    {
      path: '/',
      element: <PortugueseRoot />,
      children: portugueseRoutes
    },
  ],
    {
      basename: import.meta.env.VITE_BASE_NAME,
      future: {
        v7_normalizeFormMethod: true,
        v7_fetcherPersist: true,
        v7_normalizeScrollRestoration: true,
        v7_partialHydration: true,
        v7_relativeSplatPath: true,
        v7_skipActionErrorRevalidation: true,
        v7_startTransition: true
      }
    },
  );

  return (
      <RouterProvider router={router} />
  )
}
