import { Box, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2';
import { useContext } from 'react';

import { UiContext } from '../../context';
import { languagesTypes } from '../../types/types';
import { getImageURL } from '../../helpers';


const textTeam = {
  [languagesTypes.english]: {
    title: 'Our team',
    paragraphOne: 'The success and growth of WTA by HAS is based on our multidisciplinary, specialized, and multilingual team. Thanks to their skills and talents, our company sets trends in the market.',
    paragraphTwo: 'The innovation and the portfolio of services we offer are the result of our professional team’s efforts, always ready to provide creative and effective solutions.'
  },
  [languagesTypes.spanish]: {
    title: 'Nuestro equipo',
    paragraphOne: 'El éxito y crecimiento de WTA by HAS se sustenta en nuestro equipo multidisciplinario, especializado y multilingüe. Gracias a sus habilidades y talentos, nuestra empresa marca tendencia en el mercado.',
    paragraphTwo: 'La innovación y el portafolio de servicios que ofrecemos, son fruto del trabajo de un equipo profesional, siempre dispuesto a brindar soluciones creativas y efectivas.'
  },
  [languagesTypes.portugues]: {
    title: 'Nossa equipe',
    paragraphOne: 'O sucesso e o crescimento da WTA by HAS estão apoiados pela nossa equipe multidisciplinar, especializada e multilíngue. Graças às suas competências e talentos, nossa empresa define tendências no mercado.',
    paragraphTwo: 'A inovação e o portfólio de serviços que oferecemos são fruto do trabalho de uma equipe profissional, sempre disposto a oferecer soluções criativas e eficazes.'
  }
}
export const OurTeam = () => {
  const { currentLang } = useContext(UiContext);
  return (
    <Box component='section' sx={{
      width: '100%',
      backgroundImage: `url(${getImageURL('nuestro_equipo.webp')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      py: '75px',
    }} >
      <Grid container spacing={{ xs: 3, lg: 6 }}>
        <Grid size={{ xs: 12, md: 10, lg: 6 }}>
          <Stack sx={{
            maxWidth: '600px',
            px: { xs: '1rem', md: '0'},
            mr: { xs: '1rem', md: '0'},
            ml: 'auto',
            borderTop: '1px solid #fff',
            pt: '2rem',
          }}>

            <Typography variant='h3' sx={{
              color: '#fff',
            }} >
              {textTeam[currentLang].title}
            </Typography>
            <Typography variant='body1' sx={{
              color: '#fff',
              fontSize: {xs: '16px'},
              mb: '1rem',
            }}>
             {textTeam[currentLang].paragraphOne}
            </Typography>
            <Typography variant='body1' sx={{
              color: '#fff',
              fontSize: {xs: '16px'}
            }}>
              {textTeam[currentLang].paragraphTwo}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
