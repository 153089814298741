import { Suspense } from 'react'
import { motion } from "framer-motion";

import { Loader } from './components/Loader'
import { UiProvider } from './context'
import { AppRouter } from './router/AppRouter'
import { AppTheme } from './theme/AppTheme'


export const WtaApp = () => {

  return (
    <UiProvider>
      <AppTheme>
        <Suspense fallback={
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2 }}
          >
            <Loader />
          </motion.div>
        }>
          <AppRouter>
          </AppRouter>
        </Suspense>
      </AppTheme>
    </UiProvider>
  )
}
