import { Card, CardMedia } from '@mui/material'
import { useContext } from 'react'
import { UiContext } from '../../context'
import { languagesTypes } from '../../types/types'
import { getImageURL } from '../../helpers'

const imagesLocation = {
  [languagesTypes.english]: {
    image: getImageURL('ubi-eng.webp'),
    srcSet: `${getImageURL('ubi-eng-cel.webp')} 768w, ${getImageURL('ubi-eng.webp')} 1024w`,
  },
  [languagesTypes.spanish]: {
    image: getImageURL('ubi-pt.webp'),
    srcSet: `${getImageURL('ubi-pt-cel.webp')} 768w, ${getImageURL('ubi-pt.webp')} 1024w`,
  },
  [languagesTypes.portugues]: {
    image: getImageURL('sedes_es.webp'),
    srcSet: `${getImageURL('sedes_es-cel.webp')} 768w, ${getImageURL('sedes_es.webp')} 1024w`,
  }

}

export const OurLocations = () => {
  const { currentLang } = useContext(UiContext)
  return (
    <Card sx={{ maxWidth: '100%', height: '100%' }}>
      <CardMedia
        component="img"
        height="100%"
        width="100%"
        loading='lazy'
        sx={{
          objectFit: 'contain',
          objectPosition: 'center',
          backgroundColor: '#0D47A1',
        }}
        image={imagesLocation[currentLang].image}
        srcSet={imagesLocation[currentLang].srcSet}
        alt="Our Office"
      />
    </Card>
  )
}
