import { useLocation, useNavigate } from 'react-router-dom';

export const useNavegate = () => {

    const navigateUrl = useNavigate()
    const location = useLocation();

    const navigate = (url) => {
        if (location.pathname === url) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            navigateUrl(url);
        }
    };
    return { navigate }
}
