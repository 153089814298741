import { lazily } from 'react-lazily';

import { languagesTypes } from '../../types/types';

const { SobreNosotrosPage } = lazily(() => import('../pages/SobreNosotrosPage'));
const { NuestraHistoriaPage } = lazily(() => import('../pages/NuestraHistoriaPage'));
const { SolucionesPage } = lazily(() => import('../pages/SolucionesPage'));
const { ServiciosPage } = lazily(() => import('../pages/ServiciosPage'));
const { ContactenosPage } = lazily(() => import('../pages/ContactenosPage'));

export const spanishRoutes = [
    {
        path: 'sobre-nosotros',
        element: <SobreNosotrosPage />,
        [languagesTypes.english]: '/about-us',
        [languagesTypes.portugues]: '/sobre-nos'
    },
    {
        path: 'nuestra-historia',
        element: <NuestraHistoriaPage />,
        [languagesTypes.english]: '/our-history',
        [languagesTypes.portugues]: '/nossa-historia'
    },
    {
        path: 'soluciones',
        element: <SolucionesPage />,
        [languagesTypes.english]: '/solutions',
        [languagesTypes.portugues]: '/solucoes'
    },
    {
        path: 'servicios',
        element: <ServiciosPage />,
        [languagesTypes.english]: '/services',
        [languagesTypes.portugues]: '/servicos'
    },
    {
        path: 'contactenos',
        element: <ContactenosPage />,
        [languagesTypes.english]: '/contact-us',
        [languagesTypes.portugues]: '/contate-nos'
    },
]