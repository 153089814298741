import { Navigate } from 'react-router-dom';
import { lazily } from 'react-lazily';

import { AboutUsPage, } from '../pages';
import { languagesTypes } from '../../types/types';

const { ContactUsPage } = lazily(() => import('../pages/ContactUsPage'));
const { OurHistoryPage } = lazily(() => import('../pages/OurHistoryPage'));
const { SolutionsPage } = lazily(() => import('../pages/SolutionsPage'));
const { ServicesPage } = lazily(() => import('../pages/ServicesPage'));
const { NotFoundPage } = lazily(() => import('../pages/NotFoundPage'));


export const englishRoutes = [
    {
        path: '/',
        element: <Navigate to='about-us' replace />
    },
    {
        path: 'about-us',
        element: <AboutUsPage />,
        [languagesTypes.spanish]: '/sobre-nosotros',
        [languagesTypes.portugues]: '/sobre-nos'
    },
    {
        path: 'our-history',
        element: <OurHistoryPage />,
        [languagesTypes.spanish]: '/nuestra-historia',
        [languagesTypes.portugues]: '/nossa-historia'
    },
    {
        path: 'solutions',
        element: <SolutionsPage />,
        [languagesTypes.spanish]: '/soluciones',
        [languagesTypes.portugues]: '/solucoes'
    },
    {
        path: 'services',
        element: <ServicesPage />,
        [languagesTypes.spanish]: '/servicios',
        [languagesTypes.portugues]: '/servicos'
    },
    {
        path: 'contact-us',
        element: <ContactUsPage />,
        [languagesTypes.spanish]: '/contactenos',
        [languagesTypes.portugues]: '/contate-nos'
    },
    {
        path: '*',
        element: <NotFoundPage />,
    }
]