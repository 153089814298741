import { Box } from "@mui/material";
import { getImageURL } from '../helpers';

export const Loader = () => (
  <Box sx={loaderStyles}>
    <img src={getImageURL('preloader.gif')} alt="Loading..." />
  </Box>
);

const loaderStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
};

