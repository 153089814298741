import { Box, Typography, Stack, styled, Paper } from '@mui/material';

import { languagesTypes } from '../../types/types';
import { UiContext } from '../../context';
import { useContext } from 'react';
import { getImageURL } from '../../helpers'


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: '#fff',
  flexGrow: 1,
  backgroundColor: 'transparent',
}));

const PermanentAssistanceInfo = {
  [languagesTypes.english]: {
    title: 'Permanent Assistance',
    text1: 'We provide excellence of service, which is evidenced by our stringent assessment and systematic control processes.',
    text2: 'We perform quality control checks of services rendered, generating NPS statistics',
    text3: '97% of our calls are answered within 6 seconds. Our missed calls are less than 1.45%',
    text4: 'Evaluation to suppliers to ensure the best service',
    text5: 'We respond quickly and efficiently to unforeseen events, managing service costs successfully. Find out how we do it and ask for advice now!'
  },
  [languagesTypes.spanish]: {
    title: 'Asistencia Permanente',
    text1: 'Ofrecemos un servicio de excelencia, reflejado en nuestros rigurosos procesos de evaluación y control sistemático.',
    text2: 'Control de calidad de los servicios prestados, generando estadísticas de NPS',
    text3: '97% de nuestras llamadas son atendidas antes de 6 segundos. Nuestras llamadas perdidas son inferiores al 1,45%',
    text4: 'Evaluación a proveedores para brindar el mejor servicio',
    text5: 'Resolvemos con rapidez y eficacia cualquier imprevisto y gestionamos con éxito los costes del servicio. Conoce cómo lo hacemos, pide asesoramiento ahora mismo.'
  },
  [languagesTypes.portugues]: {
    title: 'Assistência Permanente',
    text1: 'Oferecemos um serviço de excelência, refletido nos nossos rigorosos processos de avaliação e controle sistemático.',
    text2: 'Controle de qualidade dos serviços prestados, gerando estatísticas de NPS',
    text3: '97% das nossas chamadas são atendidas em 6 segundos. Nossas chamadas perdidas são inferiores a 1,45%',
    text4: 'Avaliação de fornecedores para oferecer o melhor serviço a proveedores para brindar el mejor servicio',
    text5: 'Respondemos de forma rápida e eficaz a qualquer imprevisto, gerindo com sucesso os custos dos serviços. Descubra como fazemos isso e peça conselhos agora.'
  }
}


export const OurAssistance = () => {

  const { currentLang } = useContext(UiContext)

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: '#1044A4',
        padding: { xs: '2rem 1.5rem', md: '3.8rem 4.7rem' },
      }}
    >
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          p: { xs: '1rem', md: '2rem' },
          borderRadius: '2rem'
        }}
      >
        <Typography variant="h4"
          sx={{
            fontSize: '40px',
            fontWeight: 'bold',
            color: '#53e0b4',
            textAlign: 'center',
          }}
        >
          {PermanentAssistanceInfo[currentLang].title}
        </Typography>
        <Box
          sx={{
            padding: { xs: '1rem', md: '1rem 9rem' }
          }}
        >
          <Typography
            sx={{
              fontSize: { md: '25px' }
            }}
          >
            {PermanentAssistanceInfo[currentLang].text1}
          </Typography>
          <Stack
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 1fr)',
              padding: { md: '0 2.4rem' },
              mt: '1.5rem'
            }}
          >
            <Item
              elevation={0}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                gap: '30px'
              }}
            >
              <Box component='figure'>
                <Box component='img' src={getImageURL('control_calidad.png')} alt="Clipboard Logo " loading='lazy' className='img-our-assistance' />
              </Box>
              <Typography vaiant="body1">
                {PermanentAssistanceInfo[currentLang].text2}
              </Typography>
            </Item>
            <Item
              elevation={0}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                gap: '30px'
              }}
            >
              <Box component='figure'>
                <Box component='img' src={getImageURL('llamadas97.png')} loading='lazy' alt="Doctor Logo" className='img-our-assistance' />
              </Box>
              <Typography vaiant="body1">
                {PermanentAssistanceInfo[currentLang].text3}
              </Typography>
            </Item>
            <Item
              elevation={0}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                gap: { xs: '0', md: '30px' }
              }}
            >
              <Box component='figure'>
                <Box component='img' src={getImageURL('evaluacion_p.svg')} loading='lazy' alt="" className='img-our-assistance' />
              </Box>
              <Typography vaiant="body1">
                {PermanentAssistanceInfo[currentLang].text4}
              </Typography>
            </Item>
          </Stack>
          <Typography
            sx={{
              fontStyle: 'italic',
              textAlign: 'center',
              mt: { xs: '1rem', md: '0.1rem' }
            }}
          >
            {PermanentAssistanceInfo[currentLang].text5}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
