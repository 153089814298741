import { englishRoutes } from '../ingles/router/routes';
import { spanishRoutes } from '../español/router/routes';
import { portugueseRoutes } from '../portugues/router/routes';

/**
 * Gets the path of a url in a given language
 * @param {string} url The url to get the path for
 * @param {string} lang The language to get the path in
 * @return {string} The path of the url in the given language
 */
export const getPathByLang = (url, lang) => {
    // Get the path of the url without the hostname
    const urlPath = url.substring(url.lastIndexOf('/') + 1);

    // Combine all the routes from all the languages
    const routes = [...englishRoutes, ...spanishRoutes, ...portugueseRoutes];

    // Find the route that matches the path of the url
    const newUrl = routes.find((route) => route.path === urlPath);

    // Return the path of the url in the given language
    return newUrl[lang];
}
